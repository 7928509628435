import { AMP_EVENT_NAME } from '../../../lib/amplitude'
import {
  eventsDataset,
  TRACKER_CONSTANTS,
  GTM_ENV_VAR,
} from './vouchersTracker'

export default function trackGaVouchers(props) {
  if (props.router?.pathname === '/accounts') {
    const loginStatus = props.isLoggedIn ? 'Login' : 'Guest'
    props.track({
      ...eventsDataset[
        TRACKER_CONSTANTS.ON_VOUCHER_WALLET_ACCOUNT_VOUCHERS_TAB_CLICKED
      ],
      [GTM_ENV_VAR.EVENT_LABEL]: `Voucher-Wallet_Account_${loginStatus}`,
    })
  }

  if (props.pageName === 'cart') {
    props.track(eventsDataset[TRACKER_CONSTANTS.ON_ADD_VOUCHER_CART_CLICKED])
  }

  if (props.pageName === 'checkout') {
    props.track({
      ...eventsDataset[TRACKER_CONSTANTS.ON_ADD_VOUCHER_CHECKOUT_CLICKED],
      [GTM_ENV_VAR.AMPLITUDE_EVENT_NAME]: 'add voucher clicked',
      [GTM_ENV_VAR.AMPLITUDE_EVENT_TYPE]: AMP_EVENT_NAME.VOUCHER_EVENT_PROPERTY,
      [GTM_ENV_VAR.AMPLITUDE_VERTICAL]: 'grocery-online',
      [GTM_ENV_VAR.AMPLITUDE_CTA_LOCATION]: 'grocery-online | cart',
      [GTM_ENV_VAR.AMPLITUDE_CTA_SUBLOCATION]: 'add',
    })
  }

  if (props.title) {
    switch (props.title) {
      case 'Transaction history':
        props.track(
          eventsDataset[
            TRACKER_CONSTANTS.ON_EVOUCHER_TRANSACTION_HISTORY_CLICKED
          ]
        )
        break
      case 'How to redeem in stores':
      case 'How to redeem online':
        props.track(
          eventsDataset[TRACKER_CONSTANTS.ON_EVOUCHER_HOW_TO_REDEEM_CLICKED]
        )
        break
      case 'Where to redeem':
        props.track(
          eventsDataset[TRACKER_CONSTANTS.ON_EVOUCHER_WHERE_TO_REDEEM_CLICKED]
        )
        break
      case 'Terms & conditions':
        props.track(eventsDataset[TRACKER_CONSTANTS.ON_EVOUCHER_TNC_CLICKED])
        break
      case 'For help or enquiries':
      case 'Need help?':
        props.track(eventsDataset[TRACKER_CONSTANTS.ON_EVOUCHER_HELP_CLICKED])
        break
    }
  }
}
