import nookies from 'nookies'
import getConfig from 'next/config'
const {
  publicRuntimeConfig: { API_URL },
} = getConfig()
import {
  DY_EVENTS_PROPERTIES as DYEP,
  DY_EVENT_NAME as DYEN,
  getPageTypeByUrl,
} from '../lib/amplitude'
import { GTM_ENV_VAR as GEV } from '../lib/eventTrackerFunction'
import SessionStorageService from './SessionStorageService'
import { getConfigFor } from './configService'
import { SPLIT_FEATURES } from './constants'
import { generateQueryString } from '../lib/filterFormatter'
import { getUserType } from './userType'
import { DATADOG_FLOW_NAMES, addDatadogFlowError } from './datadog'

export const initDyCookies = async () => {
  const { _dyid_server, _dyjsession, sdt, auth_token } = nookies.get({})
  const obj = {
    orderType: getUserType() === 'B2B' ? 'B2B' : 'DELIVERY',
    dId: _dyid_server,
    dSession: _dyjsession,
    sdt: sdt,
  }

  const params = generateQueryString(obj)
  const apiURL = `${API_URL}/recommender/init${params}`
  const headers = {
    method: 'GET',
    headers: {
      ...(auth_token && { Authorization: `Bearer ${auth_token}` }),
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  }
  try {
    const response = await fetch(apiURL, headers)
    const result = await response?.json()
    if (result?.code === 200) {
      setDySession(result?.data)
    }
  } catch (error) {
    /* istanbul ignore next */
    addDatadogFlowError(error, DATADOG_FLOW_NAMES.DY_INIT_API)
  }
}

export const initDyParams = ctx => {
  const data = getDySession(ctx)
  if (data?._dyid_server && data?._dyjsession && data?.sdt) {
    return `&dId=${data?._dyid_server}&dSession=${data?._dyjsession}&sdt=${data?.sdt}`
  } else if (data?.sdt) {
    return `&sdt=${data?.sdt}`
  } else {
    return ''
  }
}

export const getDySession = (ctx = {}) => {
  // passing ctx to read cookies on server side rendering
  const { sdt, _dyid_server, _dyjsession } = nookies.get(ctx) || {}

  return {
    _dyid_server: _dyid_server,
    _dyjsession: _dyjsession,
    sdt: sdt,
  }
}

export const setDySession = (value, ctx = {}) => {
  if (!value) {
    return
  }
  const MAX_AGE = 365 * 24 * 60 * 60 // 1 year

  if (value?._dyid_server) {
    nookies.set(ctx, '_dyid_server', value?._dyid_server, {
      maxAge: MAX_AGE,
      path: '/',
    })
  }

  if (value?._dyjsession) {
    nookies.set(ctx, '_dyjsession', value?._dyjsession, {
      maxAge: MAX_AGE,
      path: '/',
    })
  }

  if (value?.sdt) {
    nookies.set(ctx, 'sdt', value?.sdt, {
      maxAge: MAX_AGE,
      path: '/',
    })
  }
}

export const clearDySession = () => {
  nookies.destroy({}, '_dyid_server')
  nookies.destroy({}, '_dyjsession')
}

export const getMetaDataWithDyIdServer = array => {
  if (!Array.isArray(array)) {
    return {}
  }

  for (let i = 0; i < array.length; i++) {
    const item = array[i]
    if (
      item?.data &&
      item?.data?.metaData &&
      item.data?.metaData?._dyid_server
    ) {
      return item.data?.metaData
    }
  }
  return {} // Return null if no object with _dyid_server property is found
}

export const setDySessionFromLayout = layout => {
  const metaData = getMetaDataWithDyIdServer(layout)
  setDySession(metaData)
}

// GA4 Event Tracking Common utils Function

export const getGA4PageType = () => {
  const routeName = SessionStorageService.getItem('currentRoute')
  return getPageTypeByUrl(routeName)
}

export const getGA4ScreenName = url => {
  const pageType = getGA4PageType()
  switch (pageType) {
    case 'home':
      return 'grocery-online | home'
    case 'promotion':
      return `grocery-online | promotion | ${url || 'all'}`
    default:
      return `grocery-online | ${pageType} | ${url}`
  }
}

export const getDyExperiments = flag => {
  const { configuration: dyConfig } =
    getConfigFor(SPLIT_FEATURES.FE_GT_REC_WTYL) || {}
  const dyExpWtyl = dyConfig?.variantKey

  return flag ? `${dyExpWtyl}` : `,${dyExpWtyl}`
}

export const getDyFBTExperiments = flag => {
  const { configuration: dyConfig } =
    getConfigFor(SPLIT_FEATURES.FE_GT_REC_FBT) || {}
  const dyExpWtyl = dyConfig?.variantKey

  return flag ? `${dyExpWtyl}` : `,${dyExpWtyl}`
}

// DY EVENTS & ENGAGEMENTS helper functions

export const getPurchaseItems = items => {
  if (!items.length) {
    return []
  }

  return items.map(item => {
    const mrp =
      Number(item?.orderDetails?.mrp) - Number(item?.orderDetails?.discount)
    return {
      [DYEP.ITEM_PRICE]: parseFloat(mrp.toFixed(2)),
      [DYEP.PRODUCT_ID]: item.clientItemId,
      [DYEP.QUANTITY]: Number(item.orderDetails.orderedQuantity),
    }
  })
}

export const getCartItems = items => {
  if (Object.keys(items).length === 0) {
    return []
  }
  const onlyCheckedItems = Object.values(items).filter(item => item.isChecked)
  if (!onlyCheckedItems.length) {
    return []
  }
  const processed = []
  onlyCheckedItems.forEach(item => {
    if (item?.count && Number(item.count) > 0) {
      const mrp = calculateMrpWithoutDiscount(item)
      if (mrp !== 0 && !item?.isFree) {
        processed.push({
          [DYEP.ITEM_PRICE]: parseFloat(mrp.toFixed(2)),
          [DYEP.PRODUCT_ID]: item?.clientItemId,
          [DYEP.QUANTITY]: item.count && Number(item.count),
        })
      }
    }
  })
  return processed
}

export const getTotalValue = data => {
  if (data.length) {
    return data.reduce((total, item) => {
      return total + item.itemPrice * item.quantity
    }, 0)
  } else {
    return 0
  }
}

export const calculateMrp = item => {
  let mrp
  if (item?.storeSpecificData && item?.storeSpecificData.length) {
    mrp =
      Number(item.storeSpecificData[0].mrp) -
      Number(item.storeSpecificData[0].discount)
  } else if (item?.mrp) {
    if (item?.discount) {
      mrp = Number(item.mrp) - Number(item.discount)
    } else {
      mrp = Number(item.mrp)
    }
  } else {
    mrp = 1
  }

  return mrp
}

export const calculateMrpWithoutDiscount = item => {
  let mrp
  if (item?.storeSpecificData && item?.storeSpecificData.length) {
    mrp = Number(item.storeSpecificData[0]?.mrp)
  } else if (item?.mrp) {
    mrp = Number(item.mrp)
  } else {
    mrp = 1
  }

  return mrp
}

export const trackingAddToCartForDY = (
  details,
  cartItems,
  delta,
  metaData = {}
) => {
  const items = cartItems || {}
  const value = getCartItems(items)
  const mrp = calculateMrp(details)
  const dy = getDySession()
  const orderType = getUserType() === 'B2B' ? 'B2B' : 'DELIVERY'
  const meta = metaData?.decisionId && metaData?.variationId
  const destination = details?.slotId ? 'dy_event, dy_engagement' : 'dy_event'
  return {
    [GEV.AMPLITUDE_EVENT_NAME]: DYEN.ADD_TO_CART_V1,
    [GEV.AMPLITUDE_DY_DESTINATIONS]: destination,
    ...(details?.slotId &&
      meta && {
        [GEV.AMPLITUDE_DY_ENGAGEMENT_PROPERTIES]: {
          [DYEP.DECISION_ID]: metaData?.decisionId,
          [DYEP.SLOT_ID]: details.slotId,
          [DYEP.TYPE]: 'SLOT_CLICK',
          [DYEP.VARIATIONS]: metaData?.variationId,
        },
      }),
    [GEV.AMPLITUDE_DY_EVENT_PROPERTIES]: {
      [DYEP.PRODUCT_ID]: details?.clientItemId,
      [DYEP.QUANTITY]: delta,
      [DYEP.DY_TYPE]: 'add-to-cart-v1',
      [DYEP.VALUE]: delta * mrp,
      [DYEP.CURRENCY]: 'SGD',
      [DYEP.CART]: [...value],
    },
    [GEV.AMPLITUDE_DY_ID]: dy?._dyid_server,
    [GEV.AMPLITUDE_DY_SESSION]: dy?._dyjsession,
    [GEV.AMPLITUDE_DY_ORDER_TYPE]: orderType,
    [GEV.AMPLITUDE_DY_SDT]: dy?.sdt,
  }
}

export const checkDyEventProp = props => {
  const { metaData } = props
  return metaData?.isDyEvent
}

export const trackingProductClickDY = (details, metaData = {}) => {
  const dy = getDySession()
  const orderType = getUserType() === 'B2B' ? 'B2B' : 'DELIVERY'
  const meta = metaData?.decisionId && metaData?.variationId
  return {
    [GEV.AMPLITUDE_EVENT_NAME]: DYEN.PRODUCT_CLICK,
    [GEV.AMPLITUDE_DY_DESTINATIONS]: 'dy_engagement',
    ...(details?.slotId &&
      meta && {
        [GEV.AMPLITUDE_DY_ENGAGEMENT_PROPERTIES]: {
          [DYEP.DECISION_ID]: metaData?.decisionId,
          [DYEP.SLOT_ID]: details.slotId,
          [DYEP.TYPE]: 'SLOT_CLICK',
          [DYEP.VARIATIONS]: metaData?.variationId,
        },
      }),
    [GEV.AMPLITUDE_DY_ID]: dy?._dyid_server,
    [GEV.AMPLITUDE_DY_SESSION]: dy?._dyjsession,
    [GEV.AMPLITUDE_DY_ORDER_TYPE]: orderType,
    [GEV.AMPLITUDE_DY_SDT]: dy?.sdt,
  }
}

export const trackingSeeAllClickDY = (metaData = {}) => {
  const dy = getDySession()
  const orderType = getUserType() === 'B2B' ? 'B2B' : 'DELIVERY'
  const meta = metaData?.decisionId && metaData?.variationId
  return {
    [GEV.AMPLITUDE_EVENT_NAME]: DYEN.SEE_ALL_CLICK,
    [GEV.AMPLITUDE_DY_DESTINATIONS]: 'dy_engagement',
    ...(meta && {
      [GEV.AMPLITUDE_DY_ENGAGEMENT_PROPERTIES]: {
        [DYEP.DECISION_ID]: metaData?.decisionId,
        [DYEP.TYPE]: 'CLICK',
        [DYEP.VARIATIONS]: metaData?.variationId,
      },
    }),
    [GEV.AMPLITUDE_DY_ID]: dy?._dyid_server,
    [GEV.AMPLITUDE_DY_SESSION]: dy?._dyjsession,
    [GEV.AMPLITUDE_DY_ORDER_TYPE]: orderType,
    [GEV.AMPLITUDE_DY_SDT]: dy?.sdt,
  }
}
